@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.hide-scroll {
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.service-card:hover {
  background-color: #fff;
  color: #113953;
}

.service-card:hover svg path {
  fill: #113953;
}

.service-card:hover .stroke-svg path {
  stroke: #113953;
  fill: transparent;
}

.service-card:hover .hidden-text {
  display: block;
}

.service-card:hover .toggle-arrow {
  display: none;
}

.calc-sticky-blue-wrap-size {
  height: calc(4 * 100vh);
  width: calc(45% + calc(.5 * 13rem));
}

.calc-sticky-blue-size {
  height: calc(100vh - 1em)
}

.prog-numbers {
  transition: all 500ms ease-in-out;
}

.max-width-parallax {
  max-width: 700px;
}

.prog-numbers.show {
  font-size: 64px !important;
}

@media screen and (min-width: 768px) {
  .service-card:hover h2 {
    display: none;
  }
}


.pedestal-gradient {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
    -webkit-mask-image: linear-gradient(to top, transparent 0%, rgba(0, 0, 0, 1.0)  10%);
}

.white-check path {
  stroke: #f2f2f2;
}

.team-member:hover .on-hover {
  display: flex;
}

.remove-bg {
  background-image: none !important;
}